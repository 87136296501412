export const environment = {
  production: true,
  apiUrl: 'https://repository.isservices.co.za/bannerprocessing/process.aspx',
  apiUrlAllLife: 'https://leads.alllife.co.za:8443/magnum/direct_api/submit_lead',
  apiUrl_hollard: 'http://fullserve.osmdialnow.co.za/vicidial/non_agent_api.php',
  localeApi: 'https://www.jeffasenmusic.co.za/api_caller.php',
  apiUrl_kingprice: 'http://ws.kingprice.co.za/prews/WebServices/Cavalry/Outbound/LeadsService.asmx?wdsl',
  apiUrl_budget: 'https://services.quickinsure.co.za/api/lead',
  apiUrl_carloan: 'https://returnxdigital.leadbyte.co.uk/integration?slice=5e1d6717da842669799576',
  apiUrl_sanlam: 'http://tracking.affcoza.com/aff_c?offer_id=2072&aff_id=18897',
  crmApiUrl: 'https://luhambomarketing.co.za/Luhambo-CRM/apis/db-api.php',
  kingPriceWriterApi: 'https://africonnect.motloungtech.africa/writers/kingPriceSheetWriter.php',
  kingPriceSubmitterApi: 'https://africonnect.motloungtech.africa/submitters/submit-kingprice-lead.php',
  readNamesApi: 'https://africonnect.motloungtech.africa/readers/at-readnames.php',
  lifeWriterApi: 'https://africonnect.motloungtech.africa/writers/atLifeWriter.php',
  autoAndGeneralWriterApi: 'https://africonnect.motloungtech.africa/writers/autoAndGeneralWriter.php',
  budgetInsuranceWriterApi: 'https://africonnect.motloungtech.africa/writers/budgetInsuranceWriter.php',
  firstForWomenWriterApi: 'https://africonnect.motloungtech.africa/writers/firstForWomenWriter.php',
  dialDirectWriterApi: 'https://africonnect.motloungtech.africa/writers/dialDirectWriter.php',
  readerApi: 'http://gilela.kalevamedia.com/apis/at-readnames.php',
  tracker: {
    trackerUsername: 'Gilela',
    trackerPassword: 'Tracker@112#',
    sourceId: '91459',
    trackerSubmitterApi: 'http://gilela.kalevamedia.com/apis/tracker-submitter.php',
    trackerWriterApi: 'http://gilela.kalevamedia.com/apis/tracker-writer.php'
  },
  firebaseConfig: {
    apiKey: "AIzaSyCVuHEz_bJsWX4jq2DAXpovwYu5zPt5ZoA",
    authDomain: "africonnect-404406.firebaseapp.com",
    projectId: "africonnect-404406",
    storageBucket: "africonnect-404406.appspot.com",
    messagingSenderId: "575929401324",
    appId: "1:575929401324:web:1a4530dfdf6ea318f80ac9",
    measurementId: "G-HEH3NPQ9KK"
  },
  campaigns: [{
    name: 'virseker',
    title: 'Virseker',
    type: 'custom', //affiliate || telesure || custom
    path: 'virseker',
    imgSrc: '',
    campaignMeta: {
      url: 'https://repository.isservices.co.za/bannerprocessing/process.aspx',
      currentDate: {
        set: false,
        apiKey: 'date'
      },
      dateOfBirth: {
        set: false,
        apiKey: '' // value of dateOfBirth must live in controls as "dateOfBirth"
      }
    },
    integrationDocUrl: '',
    apiUrl: 'https://africonnect.motloungtech.africa/api/submitter.php', // see readme to run light-web-server, this will help you run your php locally
    inputs: [
      {
        name: 'name',
        label: 'Firstname',
        type: 'input',
        inputType: 'text',
        placeholder: '',
        validations: [
          {
            validation: 'required',
            message: 'Please enter valid Firstname'
          }
        ]
      },
      {
        name: 'surname',
        label: 'Surname',
        type: 'input',
        inputType: 'text',
        placeholder: '',
        validations: [
          {
            validation: 'required',
            message: 'Please enter valid Surname'
          }
        ]
      },
      {
        name: 'tel',
        label: 'Phone Number',
        type: 'input',
        inputType: 'number',
        placeholder: '',
        validations: [
          {
            validation: 'required',
            message: 'Please enter valid Phone Number'
          },
          {
            validation: 'minlength',
            message: 'Please enter valid Phone Number'
          },
          {
            validation: 'maxlength',
            message: 'Please enter valid Phone Number'
          }
        ]
      },
      {
        name: 'vdn',
        label: 'Campaign Type',
        type: 'dropdown',
        inputType: '',
        placeholder: '',
        options: [
          {name: 'WVATW', label: 'Warmup', value: 'WVATW'}
        ],
        validations: [
        ]
      },
      {
        name: 'agentName',
        label: 'Agent Name:',
        type: 'dropdown',
        inputType: '',
        placeholder: '',
        options: [
        ],
        validations: [
        ]
      }
    ],
    sheetConfig: {
      applicationName: 'atarocol leads application',
      spreadSheetId: '1JJhXNhnIBKQiQimgE0ygv6GJpS6nPY3H46KCyLRph-Q',
      developerKey: 'AIzaSyB86WbfRkO-rdcJAPuWBNqQbSftEKBy9E0',
      writer: {
        sheetName: 'Virseker',
        url: 'https://africonnect.motloungtech.africa/api/writer-template.php'
      },
      reader: {
        sheetName: 'agent-names',
        url: 'https://africonnect.motloungtech.africa/api/readnames-template.php',
        set: true
      }
    }
  }]
};
